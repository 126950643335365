@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply bg-slate-200 text-slate-800 sm:text-lg text-base;
  }
  .overScroll {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
  }
  .overScroll::-webkit-scrollbar {
    display: none;
  }
  p {
    @apply whitespace-pre-wrap
  }
  h1 {
    @apply my-8 pb-2 text-3xl w-fit font-semibold text-slate-700 dark:text-slate-200 duration-300 bg-gradient-to-r from-rose-600 to-blue-600 bg-[length:100%_6px] bg-no-repeat bg-bottom;
  }
  h2 {
    @apply font-bold text-xl sm:text-2xl;
  }
  h3 {
    @apply text-xl sm:text-2xl font-semibold;
  }
  a {
    @apply no-underline hover:text-inherit;
  }

  .imageMaxHeight {
    max-height: calc(100vh - 10rem) !important;
  }

  .imageMaxWidth {
    max-width: calc(100vw - 10rem) !important;
  }

  .gradient {
    @apply bg-gradient-to-r from-rose-600 to-blue-600;
  }

  .gradientText {
    @apply bg-gradient-to-r from-rose-600 to-blue-600 bg-clip-text text-transparent;
  }

  .imgStyle {
    @apply cursor-pointer max-h-full;
  }

  .postImageMaxWidth {
    max-width: 12rem;
  }

  .mainColor {
    @apply text-red-600;
  }

  .postImageMaxWidthMobile {
    max-width: 10rem;
  }
}
